"use client";
import { ReactSVG } from "react-svg";

export default function Icon({
  url,
  strokeWidth,
  fill,
  stroke,
}: {
  url: string;
  strokeWidth?: number;
  fill?: string;
  stroke?: string;
}) {
  return (
    <ReactSVG
      beforeInjection={(svg) => {
        svg.querySelectorAll("path, rect, circle, polygon, polyline").forEach((el) => {
          if (el.getAttribute("stroke-linecap") === "square") {
            el.setAttribute("stroke-linecap", "round");
          }
          if (el.getAttribute("fill") && el.getAttribute("fill") !== "none"){
            el.setAttribute("fill", fill ? fill : "currentColor");
          }
          if (el.getAttribute("stroke") && el.getAttribute("stroke") !== "none"){
            el.setAttribute("stroke", stroke ? stroke : "currentColor");
          }
          // el.setAttribute("stroke-width", strokeWidth ? strokeWidth.toString() : "2");
        });
      }}
      className="h-full w-full [&_div]:h-full [&_div]:w-full [&_.injected-svg]:h-full [&_.injected-svg]:w-full flex justify-center items-center"
      src={url}
    />
  );
}
