"use client";

import clsx from "clsx";
import { useInView } from "react-intersection-observer";
import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const ColorizedText = (props: { heading: string; headingHighlightColor?: string; pinnedContainer?: any }) => {
  const tl = useRef<any>(null);
  const ctx = useRef<any>(null);
  const sectionRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);
  const gradientRef = useRef<any>(null);

  const isTangerine = props.headingHighlightColor === "tangerine-400";

  const colorizedArray = props.heading.split(/\{%|%\}/).map((segment, index) => {
    if (index % 2 === 1) {
      return (
        <span
          key={segment}
          className={clsx(
            "relative inline-block",
            isTangerine
              ? "bg-gradient-to-br from-secondary-brand-500 to-secondary-brand-500/70"
              : "bg-gradient-to-br from-primary-light-500 to-primary-light-300",
          )}
          style={{
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            color: "transparent",
          }}
        >
          {segment}
          <div
            ref={wrapperRef}
            className={clsx(
              "pointer-events-none absolute -left-[5px] top-1/2 h-full w-full -translate-y-1/2 overflow-hidden border-l-2",
              isTangerine ? "border-l-secondary-brand-500" : "border-l-primary-light-500",
              "opacity-0",
            )}
          >
            <div
              ref={gradientRef}
              className={clsx("h-full w-full -translate-x-full")}
              style={{
                background: isTangerine
                  ? "linear-gradient(90deg, rgba(255, 159, 64, 0.2) 54.94%, rgba(255, 159, 64, 0) 96.77%)"
                  : "linear-gradient(90deg, rgba(116, 65, 246, 0.2) 54.94%, rgba(116, 65, 246, 0) 96.77%)",
              }}
            ></div>
          </div>
        </span>
      );
    }

    return (
      <span
        key={segment}
        dangerouslySetInnerHTML={{
          __html: segment,
        }}
      ></span>
    );
  });

  useLayoutEffect(() => {
    if (colorizedArray.length > 1) {
      ctx.current = gsap.context((self) => {
        gsap.registerPlugin(ScrollTrigger);
        tl.current = gsap
          .timeline({
            paused: true,
          })
          .to(wrapperRef.current, {
            opacity: 1,
            duration: 0.5,
            ease: "power2.in",
          })
          .to(
            gradientRef.current,
            {
              x: 0,
              duration: 1,
              ease: "power2.in",
            },
            "<",
          );

        ScrollTrigger.create({
          trigger: sectionRef.current,
          start: "top 60%",
          pinnedContainer: props.pinnedContainer,
          onEnter: () => tl.current?.play(),
        });

        ScrollTrigger.create({
          trigger: sectionRef.current,
          start: "top bottom",
          pinnedContainer: props.pinnedContainer,
          onLeaveBack: () => tl.current?.pause(0),
        });
      }, sectionRef);
    }

    return () => {
      ctx.current?.kill();
    };
  }, [colorizedArray.length, props.pinnedContainer]);

  return <span ref={sectionRef}>{colorizedArray}</span>;
};

export default ColorizedText;
