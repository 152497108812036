import ComponentButton from "components/CtaLink";
import Icon from "components/Icon";
import typography from "components/typography";
import { assetFragment, ctaFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { ComponentListItemFragment } from "contentful/gql/graphql";
import { cn } from "utils/functions";
import richTextParser, { RichText } from "utils/richTextParser";

const ListItem = ({ listItem }: { listItem: ComponentListItemFragment }) => {
  const { icon: iconFragment, body, callToAction: callToActionFragment, ...rest } = listItem;
  const callToAction = getFragmentData(ctaFragment, callToActionFragment);
  const icon = getFragmentData(assetFragment, iconFragment);
  return (
    <div className="mb-xs flex space-x-sm">
      {icon?.url && (
        <div className="flex h-lg w-lg flex-shrink-0 rounded-full bg-icon-gradient p-2xs text-primary-light-50">
          <Icon url={icon?.url} />
        </div>
      )}
      <div>
        {rest?.heading && (
          <h3
            className={cn(
              typography.heading.secondaryComponentHeading,
              "mb-3xs font-bold tracking-normal text-primary-dark-900 dark:text-primary-neutral-100",
            )}
          >
            {rest.heading}
          </h3>
        )}
        <div
          className={cn(typography.body.secondaryComponentBody, "text-primary-dark-500 dark:text-primary-neutral-200")}
        >
          {richTextParser(body as RichText)}
        </div>
        <div className="my-sm">{callToAction?.link && <ComponentButton {...callToAction} />}</div>
      </div>
    </div>
  );
};

export default ListItem;
