import richTextParser, { RichText } from 'utils/richTextParser';
import { getFragmentData } from 'contentful/gql';
import { componentStatisticFragment } from 'contentful/fragments';
import { ComponentStatisticPanelFragment } from 'contentful/gql/graphql';
import typography from 'components/typography';
import { cn } from 'utils/functions';

const ComponentStatisticPanel = ({ statisticCollection }: ComponentStatisticPanelFragment) => {
  const statisticItems = statisticCollection?.items;

  return (
    <div>
      <ul className="grid grid-cols-2 gap-sm sm:auto-cols-fr sm:grid-flow-col-dense 2xl:gap-md">
        {statisticItems &&
          statisticItems.map((item, idx) => {
            const statistic = getFragmentData(componentStatisticFragment, item);
            return (
              <li
                key={statistic?.sys?.id}
                className={cn(
                  "rounded-lg bg-secondary-light-100/60 p-sm text-center sm:col-span-1 2xl:p-md",
                  statisticItems.length % 2 === 1 ? (idx === statisticItems.length - 1 ? "col-span-2" : null) : null,
                )}
              >
                <h3>
                  <div className={cn(typography.heading.componentHeading, "mb-3xs")}>{statistic?.heading}</div>
                  <div
                    className={cn(
                      typography.heading.cardHeading,
                      "[&_p]:m-0 [&_p]:text-xs [&_p]:text-primary-dark-500 [&_p]:md:text-base [&_p]:xl:text-lg",
                    )}
                  >
                    {richTextParser(statistic?.body as RichText)}
                  </div>
                </h3>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default ComponentStatisticPanel;
