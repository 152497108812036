"use client";

import Image from "next/image";
import React, { useCallback, useEffect, useReducer, useRef, useState, useLayoutEffect } from "react";
import { ComponentLogoCloudFragment } from "contentful/gql/graphql";
import { getFragmentData } from "contentful/gql";
import { assetFragment, entityCompanyFragment } from "contentful/fragments";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import clsx from "clsx";
import typography from "components/typography";
import { adjustImageDimensions, cn } from "utils/functions";

gsap.registerPlugin(ScrollTrigger);

const LogoBar = ({ heading, headingType, companyCollection }: ComponentLogoCloudFragment) => {
  const marqueeRef = useRef<HTMLDivElement>(null);
  const tw = useRef<GSAPTween | null>(null);
  const tl = useRef<GSAPTimeline | null>(null);
  const ctx = useRef<any>();

  let logos =
    companyCollection?.items?.map((item) => {
      const company = getFragmentData(entityCompanyFragment, item);
      const logo = getFragmentData(assetFragment, company?.logoOnLight ?? company?.logoIcon);
      const { width, height } = adjustImageDimensions({
        baseWidth: 42,
        scaleFactor: 0.55,
        width: logo?.width || 0,
        height: logo?.height || 0,
      });
      return { company, logo, width, height };
    }) || [];

  logos = [...logos, ...logos, ...logos, ...logos];

  useLayoutEffect(() => {
    ctx.current = gsap.context(() => {
      tl.current = gsap
        .timeline()
        .to(marqueeRef.current, {
          xPercent: 25,
          repeat: -1,
          duration: 20,
          ease: "none",
        })
        .totalProgress(0.5);
    }, marqueeRef); // <- Scope!
    return () => ctx.current.revert(); // <- Cleanup!
  }, [ctx]);

  return (
    <div className="flex h-full items-center sm:ml-lg">
      <h3
        className={cn(
          typography.heading.cardHeading,
          "mr-sm flex-shrink-0 text-center text-xs text-primary-dark-800/80 dark:text-primary-neutral-50/80 sm:text-sm md:text-base",
        )}
      >
        {heading}
      </h3>
      <div
        className="relative flex h-full items-center justify-start overflow-x-clip"
        style={{
          WebkitMaskImage: "linear-gradient(to right, transparent, #000 10% )",
          maskImage: "linear-gradient(to right, transparent, #000 10% )",
        }}
      >
        <div className="flex h-full -translate-x-1/4 whitespace-nowrap dark:invert" ref={marqueeRef} aria-hidden="true">
          <div className="flex h-full w-full items-center justify-center">
            {logos?.map(({ company, logo, width, height }, index) => {
              return (
                <div
                  className="mx-2xs flex h-full items-center justify-center md:mx-xs xl:mx-sm"
                  key={company?.sys.id + "-" + index}
                  style={{ width: `${width}px`, height: `${height}px` }}
                >
                  <Image
                    className="h-full object-contain object-center contrast-200 grayscale"
                    style={{ width: `${width}px`, height: `${height}px` }}
                    src={logo?.url || ""}
                    alt={company?.companyName || "Company Logo Cloud"}
                    height={height}
                    width={width}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoBar;
