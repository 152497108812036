import { CommonNode, documentToReactComponents } from "@contentful/rich-text-react-renderer";
import * as RichTextTypes from "@contentful/rich-text-types";
import { BLOCKS, Document, INLINES, MARKS } from "@contentful/rich-text-types";
import Image from "next/image";
import Hyperlink from "components/Hyperlink";
import CodeSnippets from "components/CodeSnippets/CodeSnippets";
import ComponentStatisticPanel from "components/ComponentStatisticPanel/ComponentStatisticPanel";
import ConversionPanel from "components/ConversionPanel/ConversionPanel";
import NewsletterForm from "components/Form/Newsletter";
import ListItem from "components/ListItem/ListItem";
import Testimonial from "components/Testimonial";
import VideoComponent from "components/VideoComponent/VideoComponent";

import type {
  ComponentCallToAction,
  ComponentCodeSnippetFragment,
  ComponentConversionPanelFragment,
  ComponentImage,
  ComponentListItemFragment,
  ComponentTestimonialFragment,
  ComponentVideoFragment,
  ComponentStatisticPanelFragment,
  ComponentLogoCloudFragment,
} from "contentful/gql/graphql";
import type { ReactElement, ReactNode } from "react";
import { slug } from "github-slugger";
import CtaLink from "components/CtaLink";
import typography from "components/typography";
import clsx from "clsx";
import { ReactSVG } from "react-svg";
import Icon from "components/Icon";
import { cn } from "./functions";

export interface RichText {
  json: Document;
  links?: {
    assets: {
      block: Array<{
        __typename: "Asset";
        title?: string | null;
        fileName?: string | null;
        size?: number | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        description?: string | null;
        sys?: { __typename?: "Sys"; id: string };
      } | null>;
    };
    entries: {
      block: Array<
        | ComponentCallToAction
        | ComponentTestimonialFragment
        | ComponentImage
        | ComponentCodeSnippetFragment
        | ComponentListItemFragment
        | ComponentVideoFragment
        | ComponentStatisticPanelFragment
        | ComponentConversionPanelFragment
        | ComponentLogoCloudFragment
        | ComponentTestimonialFragment
      >;
    };
  };
}

const richTextParser = (content: RichText, size?: "xs" | "sm" | "md" | "Small" | "Medium") => {
  if (!content) {
    return null;
  }
  size = size == "Medium" ? "sm" : size == "Small" ? "xs" : "xs";
  const { json, links } = content;

  const options = {
    renderText: (text: string) => {
      text = text.replace("[cross]", "").replace("[check]", "");
      return <span dangerouslySetInnerHTML={{ __html: text }}></span>;
    },
    renderMark: {
      [MARKS.BOLD]: (text: ReactNode) => <span className="font-medium">{text}</span>,
      [MARKS.ITALIC]: (text: ReactNode) => <em>{text}</em>,
      [MARKS.UNDERLINE]: (text: ReactNode) => <span className="underline">{text}</span>,
      [MARKS.CODE]: (text: ReactNode) => (
        <code className="inline-block break-all rounded bg-primary-dark-100 px-2xs font-mono text-[90%] leading-snug">
          {text}
        </code>
      ),
      [MARKS.SUBSCRIPT]: (text: ReactNode) => {
        return <span className="text-xs text-primary-light-500">{text}</span>;
      },
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: CommonNode, children: ReactNode) => {
        const { content } = node as RichTextTypes.Paragraph;
        if (content.length === 1 && content[0].nodeType === "text" && content[0].value.trim() === "") {
          return null;
        }
        return (
          <p
            className={clsx(
              // size == "md" && typography.body.secondaryComponentBody,
              size == "sm" && typography.body.cardBody,
              size == "xs" && typography.body.componentBody,
              "mb-2xs",
            )}
          >
            {children}
          </p>
        );
      },
      [BLOCKS.HEADING_1]: (node: CommonNode, children: ReactNode) => (
        <h1 className={typography.heading.pageHeading} id={slug(children as string)}>
          {children}
        </h1>
      ),
      [BLOCKS.HEADING_2]: (node: CommonNode, children: ReactNode) => (
        <h2 className={typography.heading.componentHeading} id={slug(children as string)}>
          {children}
        </h2>
      ),
      [BLOCKS.HEADING_3]: (node: CommonNode, children: ReactNode) => (
        <h3 className={typography.heading.innerSubheading} id={slug(children as string)}>
          {children}
        </h3>
      ),
      [BLOCKS.HEADING_4]: (node: CommonNode, children: ReactNode) => (
        <h4 className={typography.heading.innerSubheading} id={slug(children as string)}>
          {children}
        </h4>
      ),
      [BLOCKS.HEADING_5]: (node: CommonNode, children: ReactNode) => <h5>{children}</h5>,
      [BLOCKS.HEADING_6]: (node: CommonNode, children: ReactNode) => <h6>{children}</h6>,
      [BLOCKS.UL_LIST]: (node: CommonNode, children: ReactNode) => (
        <ul className="mb-2xs space-y-2xs pl-sm [&_li]:list-disc">{children}</ul>
      ),
      [BLOCKS.LIST_ITEM]: (node: CommonNode, children: ReactNode) => {
        const { content: nodeContent } = node as RichTextTypes.ListItem;
        if (
          nodeContent[0].nodeType === "embedded-entry-block" ||
          nodeContent[0].nodeType === "embedded-asset-block" ||
          nodeContent[0].nodeType === "blockquote"
        ) {
          return <li className="list-none">{children}</li>;
        }
        if (
          nodeContent[0].content[0].nodeType === "text" &&
          (nodeContent[0].content[0].value.startsWith("[cross]") ||
            nodeContent[0].content[0].value.startsWith("[check]"))
        ) {
          const listIcon = nodeContent[0].content[0].value.startsWith("[cross]")
            ? "e-remove-glyph-16.svg"
            : "check-glyph-16.svg";
          return (
            <li className="-ml-xs flex list-none items-baseline [&_p]:inline">
              <span
                className={cn(
                  "mr-2xs inline-block h-xs w-xs flex-shrink-0 pt-[2px]",
                  listIcon === "check-glyph-16.svg"
                    ? "text-primary-dark-500 dark:text-primary-light-500"
                    : "text-primary-light-500",
                )}
              >
                <Icon url={`/assets/icons/${listIcon}`} />
              </span>
              {children}
            </li>
          );
        }
        return <li className="[&_p]:inline">{children}</li>;
      },
      [BLOCKS.OL_LIST]: (node: CommonNode, children: ReactNode) => (
        <ol className="mb-2xs space-y-2xs pl-sm [&_li]:list-decimal">{children}</ol>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node: CommonNode) => {
        const {
          data: { target },
        } = node;
        const { id } = target.sys;
        const assets = links?.assets.block;
        const nestedAsset = assets?.filter((asset) => asset?.sys?.id === id);
        const [asset] = nestedAsset ?? [];

        if (
          asset?.url &&
          (asset?.url.includes("images.ctfassets.net") || asset?.url.includes("assets.ctfassets.net")) // Treat as image
        ) {
          return (
            <Image
              src={asset.url}
              alt={asset.title || "Image"}
              width={asset.width || "832"}
              height={asset.height || "520"}
              className="my-xs rounded-xl"
            />
          );
        } else if (asset?.url && asset.url?.includes("videos.ctfassets.net")) {
          // Treat as video
          return (
            <video
              src={asset.url + "?fm=mp4"}
              className="my-xs rounded-xl"
              width="100%"
              height="auto"
              muted
              autoPlay
              loop
              playsInline
            >
              <source src={asset.url + "?fm=mp4"} type="video/mp4" />
            </video>
          );
        }
      },
      [BLOCKS.QUOTE]: (node: CommonNode, children: ReactNode) => <blockquote>{children}</blockquote>,
      [BLOCKS.EMBEDDED_ENTRY]: (node: CommonNode) => {
        const {
          data: { target },
        } = node;
        const { id } = target.sys;
        const entries = links?.entries?.block;
        const nestedEntry = entries?.filter((entry) => entry?.sys?.id === id);
        if (!nestedEntry) {
          return null;
        }
        const [entry] = nestedEntry;

        switch (entry?.__typename) {
          case "ComponentCallToAction":
            return <CtaLink {...entry} />;
          case "ComponentTestimonial":
            return entry && <Testimonial {...entry} />;
          case "ComponentImage":
            return (
              entry?.imageDesktop?.url && (
                <Image
                  alt={entry?.alt || "Rich text image"}
                  className="mx-auto my-8 h-auto w-full rounded-xl"
                  src={entry?.imageDesktop?.url || ""}
                  width={entry.imageDesktop?.width || "832"}
                  height={entry.imageDesktop?.height || "520"}
                />
              )
            );
          case "ComponentConversionPanel":
            return (
              <div className="my-8">
                <ConversionPanel {...entry} />
              </div>
            );
          case "ComponentCodeSnippet":
            return <CodeSnippets node={entry} />;
          case "ComponentVideo":
            return <VideoComponent {...entry} />;
          case "ComponentListItem":
            return <ListItem listItem={entry} />;
          case "ComponentTestimonial":
            return <Testimonial {...entry} />;
          case "ComponentStatisticPanel":
            return <ComponentStatisticPanel {...entry} />;
          default:
            return null;
        }
      },
      [INLINES.HYPERLINK]: (node: CommonNode, children: ReactNode) => {
        const { data } = node;
        return (
          <Hyperlink
            href={data.uri}
            className="text-primary-light-500 decoration-primary-light-300 underline-offset-2 hover:underline dark:text-current dark:decoration-current"
          >
            {children}
          </Hyperlink>
        );
      },
      [BLOCKS.HR]: () => <hr className="my-sm" />,
      [BLOCKS.TABLE]: (node: CommonNode, children: ReactNode) => (
        <div>
          <table>
            <tbody>{children}</tbody>
          </table>
        </div>
      ),
      [BLOCKS.TABLE_ROW]: (node: CommonNode, children: ReactNode) => <tr>{children}</tr>,
    },
  };

  return documentToReactComponents(json, options) as ReactElement[];
};

export default richTextParser;
